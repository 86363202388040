import { Container, Heading } from "@chakra-ui/react"
import React from "react"
import ScrollComponent from "./Scrollcomponent"
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import ScrollcompontentListelement from "./ScrollComponentListElement"

function ScrollComponent6LawsCore() {
  return (
    <Container variant="layoutContainer" px={12} pt={12}>
      <ScrollcompontentListelement
        sizevariant="wide"
        title="Frischer Look"
        text1="Offen und wach."
        text2="Frischerer, wacherer Blick und freundlicheres, offenes Aussehen.
        "
      />

      <ScrollcompontentListelement
        sizevariant="wide"
        title="Sympathische Außenwirkung
        "
        text1="Für alle Geschlechter"
        text2="Sowohl Frauen als auch Männer wählen die Lidstraffung als Möglichkeit im Privat- und Berufsleben aufmerksamer und erfolgreicher zu erscheinen. "
      />
    </Container>
  )
}

export default ScrollComponent6LawsCore
