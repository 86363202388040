import { Box, Button, Container, Text, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerHyaloron from "../../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import QuadTeaser from "../../components/choc/QuadTeaser"
import ScrollComponent6LawsCore from "../../components/choc/ScrollComponent6"
import ScrollcompontentListelement from "../../components/choc/ScrollComponentListElement"
import ImgTextModule from "../../components/ImgTextModule"
import LayoutA from "../../components/layout/LayoutA"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgMaskReveal from "../../DefinitionComponents/Animation-Components/ImgMaskReveal"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"

const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA

        title={"Ästhetik und Schönheitsoperationen in Wilhelmshaven | VeoMed"}
        description={"Oberlidstraffung – Die Blepharoplastik. Schönheits-OP's, Behandlungen und Korrekturen: JadeKlinik in Wilhelmshaven."}
    >
      <ImgTextModule
        src={"280530.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        rounded="none"
        h="86vh"
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={1}
        bgColor="brand.gray.100"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          ></FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="brand.tan.ulight"
              fontWeight="normal"
              fontSize={{ base: "3xl", lg: "6xl" }}
            >
              Oberlidstraffung
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              color="brand.tan.vlight"
              mb={8}
              maxW={{ base: "100%", lg: "50%" }}
              fontSize={{ base: "2xl", lg: "4xl" }}
            >
              Die Blepharoplastik
            </Text>
          </FadeInAnimation>
          <Text color="brand.tan.vlight" fontSize="xl">
            Für einen offeneren Blick und einen wacheren Eindruck.
          </Text>
        </Box>
      </ImgTextModule>
      <LogoHeadlineTeaser
        py={24}
        headline="Müdes Aussehen ?"
        subheadline="kleiner eingriff. Große Wirkung
        "
      />
      <ImgMaskReveal
        imgsrc={"amanda-dalbjorn-Oa_xaE4WdBo-unsplash.jpg"}
        initScale={1}
        maxH={700}
        duration={1}
        delay={1}
      />
      <ScrollComponent6LawsCore />
      <LogoHeadlineTeaser
        py={24}
        headline="Wie kommt es zu Schlupflidern ?"
        subheadline=""
      />
      <Container variant="layoutContainer" px={12} pt={12}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Warum habe ich Schlupflider?"
          text1="Der normale Alterungsprozess,"
          text2="führt zu Bildung von Falten und erschlafften Hautpartien über dem Auge. Allerdings gibt es auch junge Menschen, die, gegebenenfalls erblich bedingt, darunter leiden. Diese erschlafften Hautpartien über dem Auge sorgen dafür, dass man oft als müde und erschöpft wahrgenommen wird.
          "
        />

        <ScrollcompontentListelement
          sizevariant="wide"
          title="Was ist eine Oberlidstraffung"
          text1="Blepharoplastik"
          text2="

         Die chirurgische Entfernung überschüssiger Haut, Muskeln und Fett im Bereich des Oberlids."
        />
      </Container>
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"35336.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Wie geht es weiter?
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <RouterLink isExternal={false} link={"/kontakt"}>
                <Button
                  mt={4}
                  variant="solid"
                  bg="brand.violet.medviolet"
                  color="brand.white"
                >
                  Kontakt
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>

      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5} px={32}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Beratung"
          text1="Vor dem Eingriff"
          text2="Vor dem möglichen Eingriff findet ein Planungsgespräch statt. Hier stellen wir fest, ob überhaupt eine Operation sinnvoll und geboten ist."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Planung"
          text1="Analyse und Kostendefintion"
          text2="im Anschluss findet das eigentliche Beratungsgespräch mit Dr. Jann Voltmann statt. Darin enthalten ist auch die evtl. Planung und daraus resultierende Kosten.
          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Operation"
          text1="Der große Tag."
          text2="Am Tag der Operation stellen wir gemeinsam sicher, dass der Eingriff durchgeführt werden kann. Medizinische und persönliche Anliegen werden erörtert, und nur wenn beide Seiten einer Operation zustimmen können, schreiten wir zur Tat."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Was passiert danach?"
          text1="Heilung und Nachsorge"
          text2="Wir legen gemeinsam Nachsorge- und Langzeitkontrolltermine fest, damit wir gemeinsam sicherstellen können, dass die Heilungsprozesse gut abläuft.
          "
        />
      </Container>
      <BannerHyaloron />
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5}>
        <BannerVeoJadeklinik />
      </Container>
      {/* <LogoHeadlineTeaser
        my={12}
        headline="Ästhetik mit High-Tech"
        subheadline="Fragen Sie auch gern nach diesen Verfahren"
      /> */}
      <QuadTeaser />
    </LayoutA>
  )
}

export default Blank
