/*
---------------------------------------------------------------------------
---------- TODO: MAKE CONTAINER GROW IN WIDTH WHILE SCROLLING -------------
---------------------------------------------------------------------------

animate={{
    width:  
    progress < 20 ? 1536 + ((windowWidth - 1536)/100 * progress * 100/20 ) // add width for first 20% of scrolling until maxWidth is achieved
    : 
    progress > 80 ? 1536 // reduce width for the last 20% of scrolling until initial width is achieved
    : 
    windowWidth, // if neither thhreshold of 0-20% or 80-100% is fulfilled, keep the block at maxWidth
  }}
*/

import { Box, Container } from "@chakra-ui/react"
import { motion } from "framer-motion"
import * as React from "react"

import useMediaQueryPure from "../../DefinitionComponents/Utility/Hooks/useMediaQueryPure"
import ProgressLine from "../../DefinitionComponents/Complete-Components/ProgressLine"
import GImage from "../GImage"
import GVideo from "../GVideo"
import ScrollAnimationContainer from "../../DefinitionComponents/Utility/UX/ScrollAnimationContainer"
import ScrollProgressTracker from "../../DefinitionComponents/Utility/UX/ScrollProgressTracker"
const MotionBox = motion(Box)

export default function ScrollComponent({
  scrollLength,
  progressColor,
  children,
  sticky,
  scrollStartFactor,
  scrollEndFactor,
  mediaSrc,
  video,
  bgColor,
  mediaOpacity,

  initialWidth,
  initialMinWidth,
  duration,
  delay,
  animationStartThreshold,
  animationEndThreshold,
  bezierCurve,
  showProgressLine,
  progressLineColor,
}) {
  // const windowWidth = document.body.clientWidth;
  // console.log('WINDOW WIDTH:')
  // console.log(windowWidth)

  return (
    <ScrollAnimationContainer scrollLength={scrollLength} sticky={sticky}>
      {(start, end, blockHeight) => (
        <ScrollProgressTracker
          start={start}
          end={end}
          blockHeight={blockHeight}
          scrollStartFactor={scrollStartFactor}
          scrollEndFactor={scrollEndFactor}
          startValue={0}
          endValue={100}
        >
          {progress => (
            <MotionBox
              top={0}
              height={scrollLength}
              margin="0 auto"
              maxWidth={initialWidth}
              minWidth={initialMinWidth}
              width="100%"
              boxSizing="border-box"
              display="grid"
              animate={
                progress > animationStartThreshold
                  ? {
                      maxWidth: "100%",
                      scale: 1,
                      transition: {
                        duration: duration,
                        delay: delay,
                        ease: bezierCurve,
                      },
                    }
                  : {
                      maxWidth: "100%",
                      scale: 0.8,
                      transition: {
                        duration: duration,
                        delay: delay,
                        ease: bezierCurve,
                      },
                    }
              }
            >
              <MotionBox
                className="item"
                gridRow="1"
                gridColumn="1"
                h={"100vh"}
                zIndex={-1}
                position="sticky"
                top={0}
                backgroundColor={bgColor}
              >
                {showProgressLine && (
                  <ProgressLine
                    start={start}
                    end={end}
                    scrollEndFactor={scrollEndFactor}
                    scrollStartFactor={scrollStartFactor}
                    color={progressLineColor}
                    blockHeight={blockHeight}
                  />
                )}

                {video ? (
                  <GVideo
                    src={mediaSrc}
                    style={{
                      height: 100 + "vh",
                      maxWidth: 100 + "%",
                      width: 100 + "%",
                      objectFit: "cover",
                      opacity: mediaOpacity,
                    }}
                  />
                ) : (
                  <GImage
                    h="100%"
                    width="100%"
                    maxW="100%"
                    minH="100%"
                    height="100vh"
                    objectFit="cover"
                    src={mediaSrc}
                    opacity={mediaOpacity}
                  />
                )}
              </MotionBox>

              <Container gridRow="1" gridColumn="1" variant="layoutContainer">
                {children}
              </Container>
            </MotionBox>
          )}
        </ScrollProgressTracker>
      )}
    </ScrollAnimationContainer>
  )
}
